// TOTO: Lưu dữ liệu khi user còn thao tác trên web thoát thì mất.
export const SessionStorageConfigKeys = {
  global: {},
  features: {
    khen_thuong_ky_luat: {
      shared: {
        general_category: "features.khen_thuong_ky_luat.shared.general_category",
        years_category: "features.khen_thuong_ky_luat.shared.years_category",
        namHoc_category: "features.khen_thuong_ky_luat.shared.namHoc_category",
        hocKy_category: "features.khen_thuong_ky_luat.shared.hocKy_category",
        khoa_category: "features.khen_thuong_ky_luat.shared.khoa_category",
        loai_khen_thuong_category: "features.khen_thuong_ky_luat.shared.loai_khen_thuong_category",
        loai_vi_pham_category: "features.khen_thuong_ky_luat.shared.loai_vi_pham_category",
        loai_file_bieu_mau_category: "features.khen_thuong_ky_luat.shared.loai_file_bieu_mau_category",
        hinh_thuc_ky_luat_category: "features.khen_thuong_ky_luat.shared.hinh_thuc_ky_luat_category",
      },
      cai_dat: {
        don_vi: {
          list: 'features.khen_thuong_ky_luat.cai_dat.don_vi.list'
        }
      },
      thong_ke_ggsheet: {
        ktkl_da_co_quyet_dinh: 'features.khen_thuong_ky_luat.thong_ke_ggsheet.ktkl_da_co_quyet_dinh'
      }
    },
    /**
     * Current selected template at import excel
     */
    import_excel: {
      currentSelectedTemplate: 'features.import_excel.currentSelectedTemplate',
      filterImportExcel: 'features.import_excel.filterImportExcel',
      selectedTemplateUploadExcel:
        'features.import_excel.selectedTemplateUploadExcel',
    },
  },
};
