/**
 * Cấu hình khóa Local Storage cho lưu trữ dữ liệu.
 * Yêu cầu:
 *  - Cần đặt tên khóa đúng cú pháp để tránh sự trùng lặp tên khóa.
 *  - Cần viết mô tả cho khóa đã khai báo:
 *    vd: // Khóa lưu trữ <Nội dung thông tin> trong chức năng <Tên chức năng>
 *  - Cần quy hoạch khóa dựa theo chức năng (feature) hoặc toàn ứng dung (global):
 *  - Xem mẫu ở dưới nhé!!!
 * */
export const LocalStorageConfigKeys = {
  global: {
    transloco: {
      // Khóa lưu trữ ngôn ngữ hiện tại của Transloco.
      currentLanguage: 'global.transloco.currentLangulage',
    },
    layouts: {
      themeSetting: 'global.layouts.themeSetting',
      currentThemeMode: 'global.layouts.currentThemeMode'
    },
    routing: {
      // Khóa lưu trữ URL trước đó trong định tuyến sau khi login thành công.
      previousUrl: 'global.routing.previousUrl',
      devPreviousUrl: 'global.routing.devPreviousUrl',
    },
  },
  feature: {
    auth: {
      // Khóa lưu trữ danh sách các ID hành động trong chức năng xác thực.
      actionIds: '___core_feature.authenticate.list_action_active',
      accessToken: '___core_feature.authenticate.access_token'
    },
    sinh_vien: {
      khen_thuong_ky_luat: {
        quan_ly: {
          cap_khoa: {
            ky_luat: {
              filter: "feature.sinh_vien.khen_thuong_ky_luat.quan_ly.cap_khoa.ky_luat.filter"
            },
            khen_thuong: {
              filter: "feature.sinh_vien.khen_thuong_ky_luat.quan_ly.cap_khoa.khen_thuong.filter"
            }
          },
          cap_truong: {
            ky_luat: {
              filter: "feature.sinh_vien.khen_thuong_ky_luat.quan_ly.cap_truong.ky_luat.filter"
            },
            khen_thuong: {
              filter: "feature.sinh_vien.khen_thuong_ky_luat.quan_ly.cap_truong.khen_thuong.filter"
            }
          }
        }
      },
      sinh_vien_khen_thuong_ky_luat: {
        khen_thuong: "feature.sinh_vien.sinh_vien_khen_thuong_ky_luat.filter.khen_thuong",
        ky_luat: "feature.sinh_vien.sinh_vien_khen_thuong_ky_luat.filter.ky_luat",
      }
    },

  },
};
